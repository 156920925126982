<template>
  <el-row>
    <el-col v-for="(item,index) of  baseList" :key="index" :span="6" class="box" justify="start" type="flex">

      <div class="baseBox">
        <div class="rex"></div>
        <div style="white-space: nowrap">{{ item.label }}</div>
        <el-tooltip class="item" effect="dark" :content="item.value.toString()" placement="top" :disabled="showTooltip">
          <div class="textValue" @mouseover="onShowNameTipsMouseenter($event)">{{ item.value }}</div>
        </el-tooltip>
      </div>

    </el-col>
  </el-row>
</template>

<script>
import { basicFromConfig } from '../utils/supplier-basicInfo'
import { formatDate } from '@/utils/auth/common'
import { getDictNameMaps } from '@/filters/fromDict'

export default {
  name: 'supplierBasicInfo',
  data () {
    return {
      showTooltip: false,
      baseList: [],
      byteText: ['enterpriseClassify', 'enterpriseKind', 'enterpriseLevel', 'enterpriseType', 'area'],
      byteTime: ['foundTime', 'registerTime', 'firstRegistTime'],
      byteCity: ['locationCity', 'city'],
      authCapitalDataInit: false
    }
  },
  props: {
    // 类型
    type: {
      type: String,
      default: ''
    },
    // 基本信息数据
    basicData: {
      type: Object,
      default: () => {}
    },
    // 适用资方
    authCapitalData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    basicData: function (newVal, oldVal) {
      this.inItData(newVal, 'base')
    },
    authCapitalData: function () {
      this.authCapitalDataInit = true
      this.inItData(this.basicData, 'base')
    }
  },
  methods: {

    /**
     *判断内容是否溢出添加气泡
     * @param e
     */
    onShowNameTipsMouseenter (e) {
      const target = e.target
      const containerLength = target.clientWidth
      const textLength = target.scrollWidth
      this.showTooltip = !(textLength > containerLength)
    },

    /**
     * 数据初始化
     * @param data
     * @param par
     */
    inItData (data, par) {
      if (!this.authCapitalDataInit) return
      this.baseList = basicFromConfig()
      const arr = par !== 'esa' && this.baseList
      for (const [key, value] of Object.entries(data)) {
        for (const val of arr) {
          if (key === val.prop) {
            if (this.byteCity.includes(key)) {
              val.value = Array.isArray(value) ? value.join('') : JSON.parse(value).join('')
            } else if (this.byteTime.includes(key)) {
              val.value = formatDate(value, 'YY-MM-DD')
            } else if (this.byteText.includes(key)) {
              val.value = this.setClassify(key, value)
            } else if (key === 'capitalSideCodeList') {
              if (value) {
                const arr = value.map(s => {
                  const it = this.authCapitalData.find(a => a.keyId === s)
                  return it.capitalSideName
                })
                val.value = arr.join('，')
              }
            } else {
              val.value = value
            }
          }
        }
      }
    },

    /***
     * 数据处理
     * @param name
     * @param item
     * @returns {string}
     */
    setClassify (name, item) {
      const map = new Map([
        ['area', 'BUSINESS_AREA'],
        ['enterpriseType', 'ENTERPRISE_CLASSIFY'],
        ['enterpriseLevel', 'ENTERPRISE_RANK'],
        ['enterpriseKind', 'ENTERPRISE_TYPE'],
        ['enterpriseClassify', 'ENTERPRISE_GRADING']
      ])
      if (map.has(name)) {
        const data = getDictNameMaps(map.get(name))
        if (data) {
          for (const [key, value] of Object.entries(data)) {
            if (item === value) {
              return key
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.rex {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #2862E7;
  opacity: 0.5;
  margin-right: 5px;
}

.box {
  margin: 10px 0;
  padding-left: 10px;
  font-size: 14px;
  color: #38373A;
  background-color: #F9F9F6
}

.baseBox {
  display: flex;
  flex-direction: row;
  align-items: center
}

.textValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
</style>
