/**
 * 基础信息配置
 * @returns {[{prop: string, label: string, span: number}, {prop: string, label: string, value: string, span: number}, {prop: string, label: string, value: string, span: number}, {prop: string, label: string, value: string, span: number}, {prop: string, label: string, value: string, span: number}, null, null, null, null, null, null, null, null]}
 */
export const basicFromConfig = () => {
  return [
    {
      label: '供应商名称：',
      prop: 'enterpriseName',
      span: 20
    },
    {
      label: '法人代表：',
      prop: 'legalRepresentative',
      value: '',
      span: 20
    },
    {
      label: '成立时间：',
      prop: 'foundTime',
      value: '',
      span: 5
    },
    {
      label: '所在城市：',
      prop: 'locationCity',
      value: '',
      span: 5
    },
    {
      label: '公司电话：',
      prop: 'phoneNo',
      value: '',
      span: 5
    },
    {
      label: '统一社会信用代码：',
      prop: 'creditCode',
      value: '',
      span: 50
    },
    {
      label: '公司地址：',
      prop: 'address',
      value: '',
      span: 5
    },
    {
      label: '公司传真：',
      prop: 'fax',
      value: '',
      span: 50
    },
    {
      label: '工商注册日期：',
      prop: 'registerTime',
      value: '',
      span: 5
    },
    {
      label: '注册资金(元)：',
      prop: 'registerMoney',
      value: '',
      span: 50
    },
    {
      label: '实控人：',
      prop: 'actualController',
      value: '',
      span: 5
    },
    {
      label: '简称：',
      prop: 'enterpriseShortName',
      value: '',
      span: 5
    },
    {
      label: '简码：',
      prop: 'enterpriseShortCode',
      value: '',
      span: 5
    },
    {
      label: '适用资方：',
      prop: 'capitalSideCodeList',
      value: '',
      span: 5
    },
    {
      label: '实收资本(元)：',
      prop: 'paidInMoney',
      value: '',
      span: 5
    },
    {
      label: '法务平台企业别名：',
      prop: 'easEnterpriseAlias',
      value: '',
      span: 5
    },
    {
      label: '法务平台统一社会信用代码：',
      prop: 'easCreditCode',
      value: '',
      span: 5
    }
  ]
}
